import { useEffect, useState } from 'react';
import './ContactPrivate.css';

function ContactPrivate() {
    const [Datas , setDatas] = useState();

    // const URL_API = process.env.REACT_APP_URL_API
    const URL_API = 'http://localhost:5000/api/collaborates'


    useEffect(() => {
        window.scrollTo(0, 0); // اسکرول به بالای صفحه
    },[])
    
  return (
    <div className='ContactPrivate'>
        <h1 style={{color:'white'}}>Contact Private</h1>

        <div className="ContactForm">
            <table>
                
            </table>
        </div>
    </div>
  )
}

export default ContactPrivate