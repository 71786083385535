import Ring1 from '../../assets/Ring/41wS52MGH4L._AC_SX569_.jpg';
import Ring2 from '../../assets/Ring/61c9vEdKf-L._AC_SX679_.jpg';
import Ring3 from '../../assets/Ring/61g8nsM67OL._AC_SX569_.jpg';
import Ring4 from '../../assets/Ring/61HUooMdFgL._AC_SX569_.jpg';
import Ring5 from '../../assets/Ring/71CgWtHZM7L._AC_SX679_.jpg';
import Ring6 from '../../assets/Ring/71Jdc3zRPxL._AC_SY575_.jpg';
import Ring7 from '../../assets/Ring/71JhUHg2p4L._AC_SX679_.jpg';
import Ring8 from '../../assets/Ring/71YK8KcmXDL._AC_SX679_.jpg';
import Ring9 from '../../assets/Ring/616cmwQ5tLL._AC_SX679_.jpg';
import Ring10 from '../../assets/Ring/718evhUNlvL._AC_SX679_.jpg';
// -----
import Necles1 from '../../assets/Necles/41mCtdzOvIL._AC_SX679_.jpg';
import Necles2 from '../../assets/Necles/51NZl6SvxeL._AC_SY575_.jpg';
import Necles3 from '../../assets/Necles/612WfDR4mpL._AC_SX679_.jpg';
import Necles4 from '../../assets/Necles/618E4XnodXL._AC_SX679_.jpg';
import Necles5 from '../../assets/Necles/61O6FBQG2cL._AC_SY575_.jpg';
import Necles6 from '../../assets/Necles/61QXwzJ3A4L._AC_SY575_.jpg';
import Necles7 from '../../assets/Necles/61UEMq+7dqL._AC_SX679_.jpg';
import Necles8 from '../../assets/Necles/61fRb3jJHlL._AC_SX679_.jpg';
import Necles9 from '../../assets/Necles/61kwBPNlhDL._AC_SY575_.jpg';
import Necles10 from '../../assets/Necles/61uerEy6JaL._AC_SX679_.jpg';
// ----
import ManWatch1 from '../../assets/ManWatch/51Q06pIR8dL._AC_SX679_.jpg';
import ManWatch2 from '../../assets/ManWatch/61TtSCnoVbL._AC_SY741_.jpg';
import ManWatch3 from '../../assets/ManWatch/61klhy7SwkL._AC_SX679_.jpg';
import ManWatch4 from '../../assets/ManWatch/61sI2290VGL._AC_SX679_.jpg';
import ManWatch5 from '../../assets/ManWatch/61vh+NhilDL._AC_SX522_.jpg';
import ManWatch6 from '../../assets/ManWatch/71gYWZ1VtAL._AC_SX679_.jpg';
import ManWatch7 from '../../assets/ManWatch/71uraaADEtL._AC_SX679_.jpg';
import ManWatch8 from '../../assets/ManWatch/81XaG51FFOL._AC_SX679_.jpg';
import ManWatch9 from '../../assets/ManWatch/81txOqlwqES._AC_SY741_.jpg';
import ManWatch10 from '../../assets/ManWatch/71tYmnoLR8L._AC_SX679_.jpg';
// 81txOqlwqES._AC_SY741_jpg

import  './images.css'
export default function ImagesPage() {
  return (
    <>
      <div className='container_image_page '>
        
      
        <div className='box_image'>
          <img src={Ring1} alt="random-image" />
        </div>
        <div className='box_image'>
          <img src={Ring2} alt="random-image" />
        </div>
        <div className='box_image'>
          <img src={Ring3} alt="random-image" />
        </div>
        <div className='box_image'>
          <img src={Ring4} alt="random-image" />
        </div>
        <div className='box_image'>
          <img src={Ring5} alt="random-image" />
        </div>
        <div className='box_image'>
          <img src={Ring6} alt="random-image" />
        </div>
        <div className='box_image'>
          <img src={Ring7} alt="random-image" />
        </div>
        <div className='box_image'>
          <img src={Ring8} alt="random-image" />
        </div>
        <div className='box_image'>
          <img src={Ring9} alt="random-image" />
        </div>
        <div className='box_image'>
          <img src={Ring10} alt="random-image" />
        </div>
    </div>
    <div className='container_image_page'>
        <div className='box_image'>
          <img src={Necles1} alt="random-image" />
        </div>
        <div className='box_image'>
          <img src={Necles2} alt="random-image" />
        </div>
        <div className='box_image'>
          <img src={Necles3} alt="random-image" />
        </div>
        <div className='box_image'>
          <img src={Necles4} alt="random-image" />
        </div>
        <div className='box_image'>
          <img src={Necles5} alt="random-image" />
        </div>
        <div className='box_image'>
          <img src={Necles6} alt="random-image" />
        </div>
        <div className='box_image'>
          <img src={Necles7} alt="random-image" />
        </div>
        <div className='box_image'>
          <img src={Necles8} alt="random-image" />
        </div>
        <div className='box_image'>
          <img src={Necles9} alt="random-image" />
        </div>
        <div className='box_image'>
          <img src={Necles10} alt="random-image" />
        </div>
        <div className='container_image_page'>
          <div className='box_image'>
            <img src={ManWatch1} alt="random-image" />
          </div>
          <div className='box_image'>
            <img src={ManWatch2} alt="random-image" />
          </div>
          <div className='box_image'>
            <img src={ManWatch3} alt="random-image" />
          </div>
          <div className='box_image'>
            <img src={ManWatch4} alt="random-image" />
          </div>
          <div className='box_image'>
            <img src={ManWatch5} alt="random-image" />
          </div>
          <div className='box_image'>
            <img src={ManWatch6} alt="random-image" />
          </div>
          <div className='box_image'>
            <img src={ManWatch7} alt="random-image" />
          </div>
          <div className='box_image'>
            <img src={ManWatch8} alt="random-image" />
          </div>
          <div className='box_image'>
            <img src={ManWatch9} alt="random-image" />
          </div>
          <div className='box_image'>
            <img src={ManWatch10} alt="random-image" />
          </div>










        </div>
    </div>
    </>
  )
}

 